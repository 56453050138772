import {
	helpers,
	required,
	maxLength,
} from "@vuelidate/validators";

const user_site_details = {
    namespaced: true,
    state: {
        defaultFormData: {
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        form:{
            'id':null,
            'clinic_unique_id':null,
            'location_name':null,
            'address':null,
            'city':null,
            'province':null,
            'postal':null,
            'phone_number':null,
            'fax_number':null,
        },
        validationRules: {
			form: {
				clinic_unique_id: {
					required: helpers.withMessage("Please enter clinic id.", required),
				},
                location_name:{
                    required: helpers.withMessage("Please enter location name.", required),
                },
                address:{
                    required: helpers.withMessage("Please enter address.", required),
                },
                city:{
                    required: helpers.withMessage("Please enter city.", required),
                },
                province:{
                    required: helpers.withMessage("Please enter province.", required),
                },
                postal:{
                    required: helpers.withMessage("Please enter postal.", required),
                },
                phone_number:{
                    required: helpers.withMessage("Please enter phone number.", required),
                },
                fax_number:{
                    required: helpers.withMessage("Please enter fax number.", required),
                }
            }
        },
    }
}

const user_stannp = {
    namespaced: true,
    state: {
        defaultFormData: {
            'id':null,
            'clinic_id':null,
            'account_number':null,
            'password':null,
            'apikey':null,
        },
        form:{
            'id':null,
            'clinic_id':null,
            'account_number':null,
            'password':null,
            'apikey':null,
        },
        validationRules: {
			form: {
                account_number:{
                    required: helpers.withMessage("Please enter account number.", required),
                    maxLength:helpers.withMessage("Please enter account number 20 char.", maxLength(20)),
                },
                password:{
                    required: helpers.withMessage("Please enter password.", required),
                    maxLength:helpers.withMessage("Please enter password 20 char.", maxLength(20)),
                },
                apikey:{
                    required: helpers.withMessage("Please enter api key.", required),
                    maxLength:helpers.withMessage("Please enter api key 255 char.", maxLength(255)),
                },
            }
        },
    }
}

export { user_site_details, user_stannp}