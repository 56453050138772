<template>
	<div class="">
		<!-- neet to add this class "chat-module-left" -->
		<div class="position-relative">
			<div class="open-chat-msg">
				<div class="d-flex align-items-center">
					<!-- Fade in the chat button -->
					<div class="chat-button cursor-pointer position-relative" v-if="!this.isChatModelShow && isVisible" @click="openChatBox">
						<p class="mb-0 d-flex align-items-center">
							<img src="/images/chat-icon.svg" class="chat-icon-white">
							<img src="/images/golden-chat-icon.svg" class="chat-icon-golden">
						</p>
						<transition name="fade" @after-enter="handleFadeStart" v-if="isShowLeftNotiCnt">
							<div class="animat-cell-left">
								<div class="count-circle-left " :class="msgNotificationClass" v-if="!this.isChatModelShow && isVisible">
									<!-- rotate-in-down-left -->
									<!-- rotate-in-up-left -->
									<span class="msg-notification" v-if="this.totalMessageCount > 0">{{ this.totalMessageCount  }}</span>
								</div>
							</div>
						</transition>
					</div>
					<!-- :class="{'rotate-in-down-left':!this.isChatModelShow && isVisible, 'rotate-in-up-left':!this.isChatModelShow && !isVisible}" -->
						
							<!-- <div class="animat-cell-left">
								<div class="count-circle-left rotate-in-up-left">
									<span class="msg-notification">1</span>
								</div>
							</div> -->

					<!-- Fade in the left-right arrow and the notification count -->
					<div class="left-right-arrow d-flex align-items-center ml-10 ">
						<!-- <transition name="fade" @after-enter="handleFadeComplete">
							<span class="msg-notification" v-if="!this.isChatModelShow && !isVisible && hasMoved">1</span>
						</transition> -->
						<transition name="fade" @after-enter="handleFadeComplete" v-if="isShowRightNotiCnt">
							<div class="animat-cell">
								<div class="count-circle " :class="msgNotificationRevertClass" v-if="!this.isChatModelShow && !isVisible">
									
									<span class="msg-notification" v-if="this.totalMessageCount > 0">{{  this.totalMessageCount  }}</span>
								</div>
							</div>
						</transition>
						<img src="/images/right-side-arrow.svg" @click="toggleDiv" v-show="!this.isChatModelShow && isVisible">
						<img src="/images/left-side-arrow.svg" @click="toggleDiv" v-show="!this.isChatModelShow && !isVisible">
					</div>
				</div>
				<div class="minimize-button cursor-pointer" v-if="this.isChatModelShow" @click="closeChatBox">
					<img src="/images/chat-bottom-arrow.svg">
				</div>

				<div class="chatbox-popup-box" v-if="this.isChatModelShow">
					<div class="top-grid-header d-flex justify-content-between"  v-if="!isShowChatScreen && !isShowNewMsgScreen">
						<p class="chat-title mb-0">Chats</p>
						<div class="edit-icon-msgr" @click="openNewMsgScreen">
							<Popper :placement="'top'" :content="newmessagetooltips" hover arrow>
								<img src="/images/chat-icon-black.svg" class="cursor-pointer" >
							</Popper>
						</div>
					</div>
					<div class="top-grid-header admin-chat-header d-flex align-items-center" v-else-if="isShowNewMsgScreen">
						<img src="/images/left-arrow-icon-01.svg" class="cursor-pointer backside-arrow" @click="doChatNewMsgClose()" >
					</div>
					
					<div class="top-grid-header admin-chat-header d-flex align-items-center justify-content-between" v-else>
						<img src="/images/left-arrow-icon-01.svg" class="cursor-pointer backside-arrow" @click="doChatMsgClose()">
						<p class="chat-title mb-0 text-center w-100" v-if="this.currentUserName != ''">{{ this.currentUserName }}</p>
						<p class="currentGroupName-edit mb-0 text-center w-100" v-if="!this.isEditing && this.currentGroupName != ''">{{ currentGroupName }}</p>
						<input v-if="this.isEditing" type="text" ref="groupName" v-model="currentGroupName" @blur="updateGroupName" @input="handleInputChange" class="updateGroupName-inpt text-center w-100"/>
						<div class="d-flex grp-name-edit-icon" v-if="this.currentGroupIdVal">
							<img src="/images/group-edit-icon.svg" class="cursor-pointer mr-30" @click="focusOnEdit" >
							<img src="/images/multi-user-icon.svg" class="cursor-pointer" @click="openNewMsgScreen" >
						</div>
					</div>
					<div class="chat-support-list" v-if="!isShowChatScreen && !isShowNewMsgScreen">
						<div class="chattingList-user cursor-pointer d-flex justify-content-between align-items-center">
							<div class="d-flex align-items-center">
								<div class="usr-profile-photo position-relative">
									<img src="/images/round-img-01.svg" class="" >
								</div>
								<div class="user-name-group">
									<p class="profile-name">Sentient AI</p>
									<p class="profile-tagline mb-0">The weather</p>
								</div>
							</div>
							<div class="">
								<p class="msg-notify-time mb-0">1d</p>
							</div>
						</div>

						<div class="chattingList-user cursor-pointer d-flex justify-content-between align-items-center" :class="{ 'active-notify': recentUser.notification_count > 0 }" v-for="recentUser in recentMsgUsersData" :key="recentUser.id" @click="doChatMsgOpen(recentUser)">
							<div class="d-flex align-items-center" v-if="recentUser.group_id == null">
								<div class="usr-profile-photo position-relative" v-if="recentUser.group_id != ''">
									<img src="/images/user-profile-02.svg" v-if="recentUser.role_name=='Doctor'" >
									<img src="/images/user-profile-01.svg"  v-else>
									<span class="msg-notification-02" v-if="recentUser.notification_count > 0">{{ recentUser.notification_count }}</span>
								</div>
								<div class="user-name-group">
									<p class="profile-name">{{ recentUser.user_name }}</p>
									<p class="profile-tagline mb-0 recent-coming-msg">{{ recentUser.message }}</p>
								</div>
							</div>
							<div class="d-flex align-items-center" v-else>
								<div class="usr-profile-photo position-relative">
									<img src="/images/user-group-chat.svg" >
									<span class="msg-notification-02" v-if="recentUser.notification_count > 0">{{ recentUser.notification_count }}</span>
								</div>
								<div class="user-name-group">
									<p class="profile-name">{{ recentUser.group_name }}</p>
									<p class="profile-tagline mb-0 recent-coming-msg">{{ recentUser.message }}</p>
								</div>
							</div>
							<div class="">
								<p class="msg-notify-time mb-0">{{ recentUser.last_update }}</p>
							</div>
						</div>
					</div>
					<slot >
						<div class="in-out-msg-list" v-if="isShowChatScreen || isShowNewMsgScreen">
							<div class="d-flex flex-column justify-content-between overflow-hidden h-100">
								<div class="personal-msg-screen" v-if="isShowChatScreen">
									<div v-for="(message, index) in messages" :key="message.id">
										<p class="receive-time text-center">{{  index  }}</p>
										<div v-for="msg in message" :key="msg.id">

											<div v-if="msg.isDivider && this.messageCount > 0" class="unread-messages-div unread-msg-line text-center position-relative mt-30 mb-30">
												<p class="unread-tagline mb-0">{{ this.messageCount }} UNREAD MESSAGES</p>
											</div>

											<div v-if="this.currentGroupIdVal == null && msg.message != ''">
											<div class="d-inline-block w-100" v-if="msg.sender_user_id == this.currentUserIdVal">
												<div class="incoming-chat position-relative">
													<pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
													<span class="in-msg-time-format">{{ msg.sent_time }}</span>
												</div>
											</div>
											<div class="personalChat-msg overflow-hidden" v-else>
												<div class="incoming-chat position-relative">
													<pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
													<span class="msg-time-format">{{ msg.sent_time }}</span>
												</div>
											</div>
										</div>

										<div v-if="this.currentGroupIdVal != null && msg.message != ''">
											<div class="d-inline-block w-100" v-if="msg.sender_user_id != this.currentUserIdVal">
												<div class="incoming-chat position-relative">
													<p class="grp-chat-name" :style="{ color: msg.color_code }">{{  msg.sender_name  }}</p>
													<pre class="mb-0 fs-18 message-font">{{ msg.message }}</pre>
													<span class="in-msg-time-format">{{ msg.sent_time }}</span>
												</div>
											</div>
											<div class="personalChat-msg overflow-hidden" v-else>
												<div class="incoming-chat position-relative">
													<pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
													<span class="msg-time-format">{{ msg.sent_time }}</span>
												</div>
											</div>
										</div>
									
										<div class="d-inline-block w-100" v-if="msg.sender_user_id == this.currentUserIdVal && msg.document_path != null">
											<!-- <p class="incoming-chat">{{ message.message }}</p> -->
											<slot v-if="msg.document_path != ''">
												<div class="d-flex align-items-center " :class="{ 'justify-content-end': msg.isSent, '': !msg.isSent }" v-for="(doc, index) in msg.document_path" :key="index">
													<span :href="documentUrl(doc.path)" target="_blank" class="upload-file-group text-decoration-none  position-relative" v-if="doc.file_type == 'application/pdf'">
														<div class="d-flex align-items-center justify-content-between">
															<div class="d-flex align-items-center">
																<i class="fa-regular fa-file-lines docs-file-icon"></i> 
																<div class="ml-15 mr-15">
																	<span class="docName">{{ extractFileName(doc.path)  }}</span>
																	<p class="docs-size-b mb-0">{{ doc.file_size }}</p>
																</div>
															</div>
															<span @click="downloadFile(doc.path)"><i class="fa-solid fa-download file-download-icon cursor-pointer"></i></span>
														</div>
														<span class="in-msg-time-format">{{ msg.sent_time }}</span>
													</span>
													<span v-else>
														<div class="image-preview-box mb-10 position-relative">
															<a :href="documentUrl(doc.path)" target="_blank" class="cursor-pointer">
																<img :src="documentUrl(doc.path)" alt="Image preview"/>
															</a>
															<span class="in-msg-time-format">{{ msg.sent_time }}</span>
														</div>
													</span>
												</div>
											</slot>
										</div>
										<div class="personalChat-msg overflow-hidden" v-else>
											<slot v-if="msg.document_path != ''">
												<div class="d-flex align-items-center" :class="{ 'justify-content-end': msg.isSent, '': !msg.isSent }" v-for="(doc, index) in msg.document_path" :key="index">
													<div>
														<span :href="documentUrl(doc.path)" target="_blank" class="upload-file-group text-decoration-none d-flex align-items-center justify-content-between position-relative" v-if="doc.file_type == 'application/pdf'">
															<div class="d-flex align-items-center">
																<i class="fa-regular fa-file-lines docs-file-icon"></i>
																<div class="ml-15 mr-15">
																<span class="docName">{{ extractFileName(doc.path) }}</span>
																<p class="docs-size-b mb-0">{{ doc.file_size }}</p>
																</div>
															</div>
															<span @click="downloadFile(doc.path)">
																<i class="fa-solid fa-download file-download-icon cursor-pointer"></i>
															</span>
															<span class="in-msg-time-format">{{ msg.sent_time }}</span>
														</span>
														<span v-else>
															<div class="image-preview-box mb-10 position-relative">
																<a :href="documentUrl(doc.path)" target="_blank" class="cursor-pointer">
																	<img :src="documentUrl(doc.path)" alt="Image preview"/>
																</a>
																<span class="in-msg-time-format">{{ msg.sent_time }}</span>
															</div>
														</span>
													</div>
												</div>
											</slot>
											</div>
										</div>
									</div>
								</div>
								<div class="search-to-admin-user" v-if="this.isShowNewMsgScreen">
									<div class="user-find-aria d-flex align-items-center" style="flex-wrap: wrap;">
										<p class="mb-0 to-msg fw-700">To:</p>
										<div class="select-muliName-input position-relative" style="display: flex; flex-direction: column; height: 100%; overflow-y: auto;">
											<div class="multi-user-div d-flex align-items-center flex-wrap" style="flex-grow: 1; gap: 5px;">
												<span class="selected-multi-user white-space-nowrap d-flex align-items-center" v-for="user in selectedUsers" :key="user.id">
													{{ user.full_name }}
													<img src="/images/close-icon.svg" @click="deselectUser(user)" class="cursor-pointer ml-10" style="height: calc(var(--scale-ratio) * 10px);">
												</span>
												<input type="text" class="form-control msger-input search-inp-box" placeholder="Search User..." v-model="searchQuery" @input="getUsers" style="margin-top: auto;width: calc(var(--scale-ratio) * 150px);">
											</div>
										</div>
										<img src="/images/right-arrow-icon.svg" class="cursor-pointer ml-10" @click="activeSearchedUser()" style="width: calc(var(--scale-ratio) * 50px);">
									</div>
									<ul class="search-list p-0 m-0" v-for="user in searchUsers" :key="user.id">
										<li class="cursor-pointer" @click="selectSearchedUsers(user)">
											<img src="/images/user-profile-01.svg" v-if="user.role != 'Doctor'">
											<img src="/images/user-profile-02.svg" v-else>
											<span>{{ user.full_name }}</span>
										</li>
									</ul>
								</div> 
							</div>
						</div>
						<div class="msger-inputarea" v-if="isShowChatScreen">
							<div v-if="this.file.length > 0">
								<div class="document-upload-area d-flex justify-content-center align-items-center" >
									<div v-for="(file, index) in this.file" :key="file.name">
										<div class="upload-pdf-docs cursor-pointer position-relative d-flex align-items-center justify-content-center" v-if="file.type == 'application/pdf'">
											<i class="fa-solid fa-file-pdf"></i>
											<i class="fa-solid fa-xmark remove-file" @click="removeFile(index)"></i>
										</div>
										<div class="upload-pdf-docs cursor-pointer position-relative d-flex align-items-center justify-content-center" v-if="file.type.startsWith('image/')">
											<i class="fa-solid fa-file-image"></i>
											<i class="fa-solid fa-xmark remove-file" @click="removeFile(index)"></i>
										</div>
										<!-- <div v-if="file.type.startsWith('image/')" class="upload-image-docs cursor-pointer position-relative d-flex align-items-center justify-content-center">
											<img :src="getObjectURL(file)" alt="Image preview" class="image-preview" style="height:calc(var(--scale-ratio) * 52px); width: calc(var(--scale-ratio) * 45px); position: relative;">
											<i class="fa-solid fa-xmark remove-file" @click="removeFile(index)" style="position: absolute; top: calc(var(--scale-ratio) * -10px); right: calc(var(--scale-ratio) * -10px); background-color: white; border-radius: 50%; padding: calc(var(--scale-ratio) * 5px); font-size: calc(var(--scale-ratio) * 14px);"></i>
										</div> -->
									</div>
								</div>
							</div>
							<div class="d-flex align-items-center">
								<img src="/images/attach-file.svg" class="attach-file-icon" @click="triggerFileInput">
								<div class="newMessage-type-area w-100">
									<input type="file" id="file" ref="fileInput" @change="uploadDocument" accept=".pdf, .jpg, .jpeg, .png"  style="display: none;" multiple>
									<!-- <input type="text" class="form-control msger-input cursor-auto" v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message..."> -->
									<textarea type="text" class="form-control msger-input cursor-auto" v-model="newMessage" @keydown="handleKeyDown" placeholder="Type a message..." @focus="isChatInputFocus = true" @blur="isChatInputFocus = false"></textarea>
								</div>
								<div @click="handleArrowClick" class="cursor-pointer">
									<img :src="newMessage != '' || this.file.length != 0 ? '/images/dark-sent-msg-icon.svg' : '/images/sent-msg-icon.svg'" alt="Send Message Icon" class="newMessage-send-icon">
								</div>
							</div>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
/* Transition for fading in and out */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;transition: opacity 0.5s ease;
}


.fade-enter {
  opacity: 0;
  transform: translateX(20px); 
}
.fade-enter-active {
	transition: transform 4s, opacity 0.5s 3.5s;
}
.fade-enter-to {
  /* transform: translateX(20px);  */
  opacity: 1;
}

/* Leaving (when the element is being removed from the DOM) */
.fade-leave {
  /* transform: translateX(20px);  */
  opacity: 1;
}
.fade-leave-active {
	transition: transform 4s, opacity 0.5s 3.5s;
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px); 
}

</style>
<script>
import Popper from "vue3-popper";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import { encryptMessage, decryptMessage } from '@/cryptoUtils';
import _ from "lodash";

export default {
	data() {
		return {
			isChatModelShow:false,
			isShowChatScreen:false,
			newmessagetooltips:"New Message",
			isShowNewMsgScreen:false,
			recentMsgUsersData:[],
			isVisible: true,
			hasMoved: false,  // Tracks whether the number has moved to the second element
			msgNotificationClass:'',
			msgNotificationRevertClass:'',
			isShowLeftNotiCnt:true,
			isShowRightNotiCnt:true,
			currentUserName:'',
			currentGroupName: '',
			chatType:'user',
			messages:[],
			currentUserIdVal: null,
			currentGroupIdVal: null,
			file:[],
			searchQuery: "",
			searchUsers: [],
			selectedUsers: [],
			isEditing: false,
			typingTimeout: null,
			newMessage: '',
			totalMessageCount: 0,
			isChatInputFocus:false,
			updateGroupMembers: false,
			currentUsersGroups: [],
			senderColors: {},
			unreadDividerShown: 0,
			messageCount: 0,
		}
	},
	components: {
		Popper,
	},
	methods: {
		openChatBox(){
			this.getRecentMsgUsers()
			this.isChatModelShow = true
		},
		closeChatBox(){
			this.isShowNewMsgScreen = false
			this.isShowChatScreen =false
			this.isChatModelShow = false
			this.getTotalMessageCount();
		},
		doChatMsgOpen(recentUser) {
			this.messages = [];
			this.messageCount = recentUser.notification_count;
			this.currentGroupName = '';
			this.currentUserName = '';
			this.isEditing = false;
			this.currentUserIdVal = '';
			this.currentGroupIdVal = null;
			this.chatType = 'user';
			this.file = [];
			this.newMessage = null;
			if(recentUser.group_id != null) {
				this.currentGroupName = recentUser.group_name;
				this.fetchGroupMessages(recentUser.receiver_user_id, recentUser.group_id);
				this.currentGroupIdVal = recentUser.group_id;
				this.currentUserIdVal=recentUser.current_user_id;
				this.chatType = 'group';
				this.$nextTick(() => {
					this.scrollToBottom();
				});
			} else {
				this.currentUserIdVal=recentUser.receiver_user_id;
				this.fetchMessages(recentUser.receiver_user_id);
				this.currentUserName = recentUser.user_name;
				this.chatType = 'user';
				this.$nextTick(() => {
					this.scrollToBottom();
				});
			}
			// this.scrollToBottom();
			this.isShowChatScreen = true;
		},
		doChatMsgClose(){
			this.currentGroupIdVal = null;
			this.currentUserIdVal = '';
			this.getRecentMsgUsers()
			this.isShowChatScreen = false
			this.isShowNewMsgScreen = false
			this.chatMessageUser = null
			this.senderColors = {}
			this.messageCount = 0
		},
		openNewMsgScreen(){
			this.updateGroupMembers = false;
			this.selectedUsers = [];
			this.searchUsers = [];

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			if(this.currentGroupIdVal != null) {
				this.updateGroupMembers = true;
				axios.post("chat/group/retrieve", 
					{ group_id: this.currentGroupIdVal },
					{ headers: headers }
				).then((response) => {
					if (response.data.status === 200) {					
						this.selectedUsers = Object.values(response.data.data.members_data);
					}
				}).catch((error) => {
					console.error("Error fetching messages:", error);
				});
			}

			this.isShowChatScreen = false
			this.isShowNewMsgScreen = true
		},
		doChatNewMsgClose(){
			this.currentGroupIdVal = null;
			this.currentUserIdVal = null;
			this.searchQuery = null;
			this.recentMsgUsersData = [];
			this.getRecentMsgUsers();
			this.isShowNewMsgScreen = false;
			this.isShowChatScreen = false;
			this.senderColors = {};
		},
		setUnreadDividerShown() {
			this.unreadDividerShown++;
		},
		getObjectURL(file) {
			if (file && file.type.startsWith('image/')) {
				return URL.createObjectURL(file);
			}
			return '';
		},
		documentUrl(documentPath) {
			return `${process.env.VUE_APP_STORAGE_URL}/chat/document/view/${documentPath}`;
        },
		decryptMessage(message) {
			return decryptMessage(message);
		},
		extractFileName(filePath) {
			return filePath.substring(filePath.lastIndexOf('/') + 1);
		},
		removeFile(index) {
			this.file.splice(index, 1);
		},
		fetchMessages(receiverUserId) {
			if (!receiverUserId) {
				return false;
			}

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			axios.post("chat/get-message", 
				{
				receiver_user_id: receiverUserId,
				chatType: this.chatType
				},
				{ headers: headers }
			).then((response) => {
				if (response.data.status === 200) {					
					this.messages = {};
					let previousDateKey = null;
					let dividerAdded = false;

					Object.keys(response.data.data).forEach((dateKey) => {
						this.messages[dateKey] = [];
						
						response.data.data[dateKey].forEach((detail) => {
							detail.message = decryptMessage(detail.message);
							this.messages[dateKey].push(detail);
						});

						if (dateKey === 'Today' && !dividerAdded) {
							const allSent = this.messages[dateKey].every(msg => msg.status === 'Sent');
							const isOnlyToday = Object.keys(this.messages).length === 1;
							
							const lastReadIndex = this.messages[dateKey]
								.map(msg => msg.status === 'Received')
								.lastIndexOf(true);

							if (allSent && previousDateKey && !isOnlyToday) {
								this.messages[previousDateKey].push({ isDivider: true, message: '', from: 'all_sent' });
							} else {
								if (lastReadIndex !== -1 && lastReadIndex < this.messages[dateKey].length - 1) {
									this.messages[dateKey].splice(lastReadIndex + 1, 0, { isDivider: true, message: '', from: 'last_index_but_not_read' });
								}
								dividerAdded = true;
							}
						}

						previousDateKey = dateKey;
					});
				}
			}).catch((error) => {
				console.error("Error fetching messages:", error);
			});
		},
		fetchGroupMessages(receiverUserId, groupId) {
			if (!groupId) {
				return false;
			}

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			axios.post("chat/get-message", 
				{
				receiver_user_id: receiverUserId,
				chatType: 'group',
				group_id: groupId
				},
				{ headers: headers } 
			).then((response) => {
				if (response.data.status === 200) {					
					this.messages = {};
					let previousDateKey = null;
					let dividerAdded = false;

					Object.keys(response.data.data).forEach((dateKey) => {
						this.messages[dateKey] = [];
						
						response.data.data[dateKey].forEach((detail) => {
							detail.message = decryptMessage(detail.message);
							this.messages[dateKey].push(detail);
						});

						if (dateKey === 'Today' && !dividerAdded) {
							const allSent = this.messages[dateKey].every(msg => msg.status === 'Sent');
							const isOnlyToday = Object.keys(this.messages).length === 1;
							
							const lastReadIndex = this.messages[dateKey]
								.map(msg => msg.status === 'Received')
								.lastIndexOf(true);

							if (allSent && previousDateKey && !isOnlyToday) {
								this.messages[previousDateKey].push({ isDivider: true, message: '', from: 'all_sent' });
							} else {
								if (lastReadIndex !== -1 && lastReadIndex < this.messages[dateKey].length - 1) {
									this.messages[dateKey].splice(lastReadIndex + 1, 0, { isDivider: true, message: '', from: 'last_index_but_not_read' });
								}
								dividerAdded = true;
							}
						}

						previousDateKey = dateKey;
					});
				}
			}).catch((error) => {
				console.error("Error fetching messages:", error);
			});
		},
		handleKeyDown(event) {
			if (event.key === 'Enter') {
				if (event.shiftKey) {
					return;
				} else {
					event.preventDefault();
					if (event.key == 'Enter'|| event.type == 'click') {
						this.sendMessage(event);
					}
				}
			}
		},
		handleArrowClick(event) {
			this.$nextTick(() => {
				this.sendMessage(event);
			});
		},
		async sendMessage(event) {
			event.preventDefault();
			// if ((event.key == 'Enter' && !event.shiftKey )|| event.type == 'click') {
				// event.preventDefault();
				
				if (this.newMessage != null || this.file.length != 0) {
					if(this.file.length > 0 && this.newMessage == null) {
						this.newMessage = null;
					} else {
						this.newMessage = this.newMessage.trim();
					}
					const encMsg = encryptMessage(this.newMessage);
					this.newMessage = null;

					const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data',
					},
					};

					const formData = new FormData();
					formData.append('message', encMsg);
					this.file.forEach((file, index) => {
						formData.append(`file${index}`, file);
					});
					if (this.currentGroupIdVal != null) {
						formData.append('group_id', this.currentGroupIdVal);
						formData.append('receiver_user_id', 0);
					} else {
						formData.append('receiver_user_id', this.currentUserIdVal);
					}

					const response = await axios.post("chat/send-message", formData, config);
					if (response.data.status === 200) {
						const detail = response.data.data;
						detail.type = 'message';
						detail.group_id = response.data.data.group_id;
						this.sendMsgToSocket(detail);
						detail.type = 'notification';
						// this.sendMsgToSocket(detail);
						detail.message = decryptMessage(detail.message);
						this.updateMessage(detail);
						// this.updateStatus(detail.id);
						this.newMessage = null;
						this.file = [];
						this.$refs.fileInput.value = ''; 
					} else if (response.data.status === 422) {
						const errorData = response.data.data;
						for (const key in errorData) {
							if (Object.prototype.hasOwnProperty.call(errorData, key)) {
								const errors = errorData[key];
								errors.forEach((error) => {
									this.$filters.moshaToast(error, "error");
								});
							}
						}
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}

					this.newMessage = null;
					this.file = [];
					this.scrollToBottom();
					const div = document.getElementsByClassName("unread-messages-div")[0];
					if (div) {
						div.remove();
					}
				}
			// }
		},
		editGroupName() {
			this.isEditing = true;
		},
		handleInputChange() {
			// clearTimeout(this.typingTimeout);
			// this.typingTimeout = setTimeout(this.updateGroupName, 1000);
		},
		getRandomColorCode() {
			let r = 0, g = 0, b = 0;
			do {
				r = this.randNumber(0, 128);
				g = this.randNumber(0, 128);
				b = this.randNumber(0, 128);
			} while (Math.abs(r - g) < 30 || Math.abs(g - b) < 30 || Math.abs(r - b) < 30);

			return `rgb(${r}, ${g}, ${b})`;
		},
		randNumber(min, max) {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		},
		updateGroupName() {
			if (this.currentGroupName.length > 27) {
				this.currentGroupName = this.currentGroupName.substring(0, 27) + '...';
			}

			const headers = {
			'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
			'Content-Type': 'application/json',
			};
			axios.post("chat/group/update-group-name", { id: this.currentGroupIdVal, name: this.currentGroupName }, headers).then(() => {
				this.$filters.moshaToast("Group name has been updated successfully.", "success");
			});
			this.isEditing = false;
		},
		updateMessage(newMsg) {
			if (!('Today' in this.messages)) {
				this.messages['Today'] = [];
			}
			this.messages['Today'].push(newMsg);
		},
		scrollToBottom: _.debounce(function() {
            const msgScreen = document.querySelector(".personal-msg-screen");
            const unreadMessageDiv = document.querySelector(".unread-messages-div");
            if (msgScreen) {
                if (unreadMessageDiv) {
                    msgScreen.scrollTop = unreadMessageDiv.offsetTop - 110;
                } else {
                    msgScreen.scrollTop = msgScreen.scrollHeight;
                }
            }
        }, 1000),
		showNotification(title, message, options = {}) {
			if (!('Notification' in window)) {
				console.error('This browser does not support notifications.');
				return;
			}

			const currentBaseURL = window.location.origin;

			const notificationChannel = new BroadcastChannel('notification_channel');

			let shouldShowNotification = true;

			notificationChannel.onmessage = (event) => {
				if (event.data.type === 'NOTIFICATION_SHOWN' && event.data.baseURL === currentBaseURL) {
					shouldShowNotification = false;
				}
			};

			setTimeout(() => {
				if (shouldShowNotification) {
					if (Notification.permission === 'granted') {
						new Notification(title, { ...options, body: message });
						notificationChannel.postMessage({ type: 'NOTIFICATION_SHOWN', baseURL: currentBaseURL });
					} else if (Notification.permission !== 'denied') {
						Notification.requestPermission().then(permission => {
							if (permission === 'granted') {
								const notification = new Notification(title, { body: message });
								notification.onclick = () => {
									console.log("at here to click on notification");
									window.focus(); 
								};
								notificationChannel.postMessage({ type: 'NOTIFICATION_SHOWN', baseURL: currentBaseURL });
							}
						});
					}
				}
			}, 100);
		},
		handleReceivedData(socketData) {
			if (typeof socketData === 'string') {
				var msgDetail = JSON.parse(socketData);
				if (msgDetail.type == 'message' && msgDetail.status != 'Failed') {
					if(msgDetail.group_id == null || msgDetail.group_id == '') {
						if(msgDetail.receiver_user_id == localStorage.getItem('userId')) {
							msgDetail.message = decryptMessage(msgDetail.message)
							if(this.isChatModelShow == true || this.isShowChatScreen == true) {
								if(msgDetail.sender_user_id == this.currentUserIdVal) {
										this.updateStatus(msgDetail.id)
										msgDetail.status = 'Received'
										msgDetail.isSent = false
										this.updateMessage(msgDetail);
										this.scrollToBottom();
										// this.getRecentMsgUsers();
								}
							} 
							if(this.isChatModelShow == false || this.isShowChatScreen == false){
								// this.$filters.moshaToast(msgDetail.sender_name + " : " + msgDetail.message, "success");
								this.showNotification(msgDetail.sender_name, msgDetail.message);
								this.getTotalMessageCount();
								this.getRecentMsgUsers();
							}
						}
					} else {
						if(this.currentUsersGroups.includes(Number(msgDetail.group_id))) {
							msgDetail.message = decryptMessage(msgDetail.message)
							if(msgDetail.sender_user_id != localStorage.getItem('userId')) {
								if(this.isChatModelShow == true && msgDetail.group_id == this.currentGroupIdVal || this.isShowChatScreen == true) {
									this.updateStatus(msgDetail.id)
									msgDetail.status = 'Received'
									msgDetail.isSent = false
									if (!this.senderColors[msgDetail.sender_user_id]) {
										this.senderColors[msgDetail.sender_user_id] = this.getRandomColorCode();
									}
									msgDetail.color_code = this.senderColors[msgDetail.sender_user_id];
									this.updateMessage(msgDetail);
									this.scrollToBottom();
									// this.getRecentMsgUsers();
								} 
								if(this.isChatModelShow == false || this.isShowChatScreen == false) {
									// this.$filters.moshaToast(msgDetail.sender_name + " : " + msgDetail.message, "success");
									this.showNotification(msgDetail.group_name, msgDetail.message);
									this.getTotalMessageCount();
									this.getRecentMsgUsers();
								}
							}	
						}
					}
				}
			}
		},
		updateStatus(msgId) {
			const headers = {
			'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
			'Content-Type': 'application/json',
			};
			axios.post("chat/update-message-status", { id: msgId, status: 'R' }, headers).then(() => {});
		},
		getRecentMsgUsers(){
			axios.post("chat/recent-message-users",{  chatType:'user'})
				.then((response) => {
					if (response.status == 200) {
						this.recentMsgUsersData = [];
						const tempUserData = []
						// this.recentMsgUsersData=response.data.data;
						$.each(response.data.data, function(key, msgDetail) {
							msgDetail.message = decryptMessage(msgDetail.message)
							tempUserData.push(msgDetail)
						});
						this.recentMsgUsersData=tempUserData
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
			}).catch(error => {
				this.loader = false
				if (error.response.status === 422) {
					this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
				} else {
					this.$filters.moshaToast(error.response.data.message, "error");
				}
			})
		},
		sendMsgToSocket(newMsg)  {
			try {
				newMsg.timeStamp = Date.now(),
				this.$socket.send(newMsg);
			
			}catch (error) {
			this.$filters.moshaToast('WebSocket send error:'+error, "error");
			}
        },
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		uploadDocument(event) {
			const added_files = Array.from(event.target.files); // Convert the FileList to an array
			const validFiles = [];

			added_files.forEach((file) => {
				if (this.validateFile(file)) {
				validFiles.push(file); // Add valid files to the validFiles array
				} else {
				this.$filters.moshaToast(`File ${file.name} not valid pdf or image file.`, "error");
				}
			});

			// if (validFiles.length > 0) {
				// this.file = validFiles; // Update the files array with only valid files
				this.file.push(...validFiles); 
			// } else {
				// this.file = [];
				this.$refs.fileInput.value = null;
			// }
		},
		validateFile(file) {
          // Perform file validation here, e.g., check file type and size
          const allowedTypes = ['application/pdf', 'image/png','image/jpg', 'image/jpeg'];
        //   const maxFileSize = 2 * 1024 * 1024; // 5 MB

          if (allowedTypes.includes(file.type)) {
            return true; // File is valid
          } else {
            return false; // File is invalid
          }
        },
		toggleDiv() {
			if (this.isVisible) {
				this.msgNotificationClass= ''
				this.msgNotificationRevertClass='rotate-in-down-left'
			} else {
				this.msgNotificationRevertClass= ''
				this.msgNotificationClass= 'rotate-in-up-left'				
			}

			this.isVisible = !this.isVisible;
			setTimeout( function () {
				this.isShowRightNotiCnt = !this.isShowRightNotiCnt
				this.isShowLeftNotiCnt = !this.isShowLeftNotiCnt
			}, 100 );

		},
		getUsers() {
			// if (this.searchQuery.length > 0) {
				const headers = {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'application/json', 
				};

				let selectedUsersIDs = [];

				this.selectedUsers.forEach((user) => {
					if(user.user_id) {
						selectedUsersIDs.push(user.user_id);
					} else {
						selectedUsersIDs.push(user.id);
					}
				});

				axios.post("chat/get-users-list", 
					{name: this.searchQuery,searched_users: selectedUsersIDs},
					{ headers: headers }
				).then((response) => {
					if (response.data.status === 200) {
						this.searchUsers = response.data.data;
					}
				}).catch((error) => {
					console.error("Error fetching messages:", error);
				});
			// }
		},
		selectSearchedUsers(userData) {
			this.selectedUsers.push(userData);
			this.searchQuery = '';
			this.searchUsers = [];
		},
		deselectUser(userData) {
			this.selectedUsers = this.selectedUsers.filter(u => (u.id ?? u.user_id) !== (userData.id ?? userData.user_id));
			this.searchQuery = '';
			this.searchUsers = [];
		},
		activeSearchedUser() {
			this.isShowNewMsgScreen = false;
			this.newMessage = null;
			this.message = [];
			this.currentUserName = '';
			this.currentGroupName = '';
			this.isEditing = false;
			if(this.updateGroupMembers == false) {
				this.currentGroupIdVal = null;
			}
			if(this.selectedUsers.length == 1) {
				this.chatType = 'user';
				this.updateGroupMembers = false;
				this.currentGroupIdVal = null;
				let user = this.selectedUsers[0];
				this.currentUserIdVal = user.id;
				if(user.user_id) {
					this.currentUserIdVal = user.user_id;
				}
				this.fetchMessages(user.id);
				this.currentUserName = '';
				this.currentUserName = user.full_name;
				this.scrollToBottom();
				this.isShowChatScreen = true;
			} else {
				let groupName = '';
				let method = 'create';
				let selectedUsersIDs = [];

				this.selectedUsers.forEach((user, index) => {
					if (user.user_id) {
						selectedUsersIDs.push(user.user_id);
					} else if (user.id) {
						selectedUsersIDs.push(user.id);
					}

					if (index === 1 && this.selectedUsers.length > 2) {
						groupName += user.full_name + ', ...';
					} else if (index < 2) {
						groupName += user.full_name + ', ';
					}
				});

				// Remove the trailing comma and space
				groupName = groupName.slice(0, -2);

				// Limit the group name to 22 characters and add ellipsis if it's longer
				if (groupName.length > 27) {
					groupName = groupName.substring(0, 27) + '...';
				}

				if(this.updateGroupMembers == true) {
					method = 'update';
				}
				
				const headers = {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'application/json', // Adjust this as needed for your API
				};

				axios.post("chat/group/save", 
					{
						group_name: groupName,
						users: selectedUsersIDs,
						id: this.currentGroupIdVal,
						method: method
					},
					{ headers: headers }  // Pass headers as a separate configuration object
				).then((response) => {
					if (response.data.status === 200) {
						// open chat window with group name
						this.messages = [];
						this.currentGroupName = response.data.data.group_name;
						this.currentGroupIdVal = response.data.data.id;
						this.currentUserIdVal = response.data.data.current_user_id;
						this.scrollToBottom();
						this.fetchGroupMessages(0, this.currentGroupIdVal);
						this.isShowChatScreen = true;
					}
				}).catch((error) => {
					console.error("Error fetching messages:", error);  // Add error handling
				});
			}
		},
		getTotalMessageCount() {
			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json', // Adjust this as needed for your API
			};

			axios.post("chat/count", 
				{},
				{ headers: headers }  // Pass headers as a separate configuration object
			).then((response) => {
				if (response.data.status === 200) {
					this.totalMessageCount = response.data.data.count;
					this.currentUsersGroups = response.data.data.groups;
				}
			}).catch((error) => {
				console.error("Error fetching messages:", error);  // Add error handling
			});
		},
		focusOnEdit() {
			this.isEditing = true;
			this.$nextTick(() => {
				this.$refs.groupName.focus();
			});
		},
		downloadFile(file_name) {
			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};
			axios({
				url: "chat/download",
				method: 'POST',
				headers: headers,
				responseType: 'blob',
				data: {
					file_name: file_name,
				},
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: response.headers['content-type'] });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file_name);
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error("Error downloading the file:", error);
			});
		},
		triggerMove() {
			this.hasMoved = true;  // Triggers the move of the notification count
		},
		handleFadeStart(){
			// Ensure that this only executes once
			if (!this.hasMoved) {
				this.triggerMove(); // Move to the second element after the fade transition completes
			}
			// this.msgNotificationClass= ''
			// this.msgNotificationRevertClass=''
		},
		handleFadeComplete() {
			// Ensure that this only executes once
			if (!this.hasMoved) {
				this.triggerMove(); // Move to the second element after the fade transition completes
			}
			// this.msgNotificationRevertClass= 'rotate-in-up-left'
			// this.msgNotificationClass= ''
		},
		handleTransitionEnd() {
			if (!this.hasMoved) {
				this.triggerMove();
			}
		},
		beforeUnmount() {
			this.$socket.onClose();
		}

	},
	mounted() {
		this.$nextTick(() => {
			this.triggerMove(); // Start the transition when the component is mounted
		});
		this.getTotalMessageCount();
	},
	created(){
		this.closeChatBox()
	},
	computed:{
      isLogin() {
        return localStorage.getItem("authToken") ? true : false;
      },
      socketData() {
        return this.$store.getters.getSocketData;
      },
    },
	watch: {
      socketData: {
        handler(newData) {
          if (newData) {
            this.handleReceivedData(newData);
          }
        },
        immediate: true,
      },
    },
}
</script>