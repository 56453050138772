import {
	helpers,
	required,
	email,
} from "@vuelidate/validators";

const getCurrentTime = () => {
    const now = new Date();
    now.setMinutes(Math.round(now.getMinutes() / 5) * 5, 0, 0);
    return now.toLocaleTimeString('en-GB', { hour12: false });
};


const customRequired = helpers.withMessage(
	'Please select specialist or enter fax number',
	(value, vm) => {
		return !!(vm.phoneNumbers.length > 0 || vm.specialist_id.length > 0);
	}
  );

const consultations = {
	namespaced: true,

	state: {		
		defaultFormData: {
			id: null,
			referring_practitioner_id: null,
			referral_date: new Date().toISOString().slice(0, 10),
			specialist_id:null,
			specialist_is_customized:null,
			referrer_instructions: null,
			urgency: 'Not Urgent',
			patient_will_book: 0,
			status:'Appointment Booked',
			appointment_id:null,
			last_follow_up_date:null,
			reason_for_consultation:null,
			significant_concurrent_problems:null,
			current_medications:null,
			allergies:null,
			attachments:null,
			include_fax_cover_page:0,
			other_information:null,
			appointment_date: new Date().toISOString().slice(0, 10),
			appointment_time:getCurrentTime(),
			keyword:'',
			advanceFilter:[]
		},
		form: {
			keyword:'',
			advanceFilter:[]
		},
		validationRules: {
			form: {
				referring_practitioner_id: {
					required: helpers.withMessage("Please Select Referring Practitioner.", required),
				},
				referral_date: {
					required: helpers.withMessage("Please Select Referral Date.", required),
				},
				service_id: {
					required: helpers.withMessage("Please Select Service for consultation.", required),
				},
				specialist_id: {
					required: helpers.withMessage("Please Select Service for specialist.", required),
				},
				urgency: {
					required: helpers.withMessage("Please Select urgency.", required),
				},
			},
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
				phoneNumbers:{
					customRequired,
				},
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
		list: [],
		faxForm:{
			specialist_id:null,
			fax_number:null,
			phoneNumbers:[]
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null,
			phoneNumbers:[]

		}

	},

	getters: {},
	mutations: {},
	actions: {},
};


export default consultations;
