import { helpers, required, email } from "@vuelidate/validators";

const customRequired = helpers.withMessage(
	'Please select specialist or enter fax number',
	(value, vm) => {
		return !!(vm.phoneNumbers.length > 0 || vm.specialist_id.length > 0);
	}
  );

const prescriptions = {
	namespaced: true,

	state: {
		listUrl: "",
		header: [],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		defaultFormData: {
			id: null,
			generic_id: null,
			med_id: null,
			generic_name: null,
			drug_name: null,
			manufacture: null,
			start_date: new Date().toISOString().slice(0, 10),
			qty: null,
			repeats: 0,
			eTreatment_type: null,
			status: null,
			notes: null,
			drug_listing_date: null,
			unit_price: null,
			covered_price: null,
			strength: null,
			dosage_form: null,
			coverage_status: null,
			annotation_note: null,
			keyword: null,
			advanceFilter: null			
		},
		form: {
		},
		validationRules: {
			form: {
				generic_name: {
					required: helpers.withMessage("Please Enter Generic Name.", required),
				},
			},
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
				phoneNumbers:{
					customRequired,
				},
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		vuelidateExternalResults: {
			form: {
				
			}
		},
		list: [],
		faxForm:{
			specialist_id:null,
			fax_number:null,
			phoneNumbers:[]
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null,
			phoneNumbers:[]

		}
	},

	getters: {},
	mutations: {},
	actions: {},
};


export default prescriptions;
